export enum Size {
    XS = "XS",
    S = "S",
    M = "M",
    L = "L",
    XL = "XL",
    XL2 = "2XL",
    XL3 = "3XL",
    XL4 = "4XL",
    XL5 = "5XL",
    XL6 = "6XL",
}
